import React from "react";
import {createRoot} from "react-dom/client";
import ReactModal from "react-modal";
import LogRocket from "logrocket";
import BidWebRoot from "./BidWebRoot";
import {runOnDOMContentLoaded} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {AppController, store} from "@nextlot/core";
import PusherAdapterWebJS from "./services/PusherAdapterWebJS";

// https://immerjs.github.io/immer/installation/
import {enableMapSet} from "immer";
enableMapSet();


export default class BidWebApp {

    static mount() {

        // LogRocket: only setup if specifically enabled
        if (NextLotJSDATA.logrocket_app_id) {
            LogRocket.init(NextLotJSDATA.logrocket_app_id, {
                release: __BUILD_TAG__,
            });

            if (NextLotJSDATA.bidder_id) {
                LogRocket.identify(NextLotJSDATA.bidder_id.toString(), {
                    siteId: NextLotJSDATA.site_id,
                    siteNameShort: NextLotJSDATA.site_name_short,
                });
            }
        }


        runOnDOMContentLoaded(async () => {

            const rootEl = document.getElementById('webAppRootContent');
            if (rootEl) {

                await AppController.init({
                    appConfig: {
                        site_id: NextLotJSDATA.site_id,
                        site_timezone_name: NextLotJSDATA.site_timezone_name,
                        site_initial_catalog_lots_view_mode: NextLotJSDATA.site_initial_catalog_lots_view_mode,

                        frontend_api_base_url: NextLotJSDATA.frontend_api_base_url,

                        bidder_id: NextLotJSDATA.bidder_id,
                        bidder_email: NextLotJSDATA.bidder_email,
                        bidder_token: NextLotJSDATA.bidder_token,
                    },

                    pusherAdapter: new PusherAdapterWebJS({
                        siteId: NextLotJSDATA.site_id,
                        pusherAppKey: NextLotJSDATA.pusher.app_key,
                        pusherCluster: NextLotJSDATA.pusher.cluster,
                        frontendApiBaseUrl: NextLotJSDATA.frontend_api_base_url,
                    }),

                    onAuthenticationRemoved: () => {
                        // reload the entire page when the authentication was lost (e.g. APIKey bidder token invalidated remotely, etc)
                        window.location.reload();
                    }});

                // set the modal app element to the `main` container, to cover any possible alert banner showing at the top
                ReactModal.setAppElement(rootEl.parentElement);

                createRoot(rootEl).render(<BidWebRoot currentBidder={AppController.instance.currentBidder} />);
            }
        });
    }
}


