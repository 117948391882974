import {createAction} from "@reduxjs/toolkit";
import {WebcastAuctionConnectData_TypeDef} from "../services/AuctionsManager.js";
import {
    Bid,
    PMAuctionDataSyncStatusChanged,
    PMChoiceLotsSelected,
    PMAuctionDataSnapshot,
    PMWebcastStatusChanged,
    Round,
    PMBiddingUpdated,
    PMParticipantsUpdated, ChatMessage, ChatRoom, Lot, LotDetails
} from "../data/data_types_definitions";
import {WbsCommStatesEnum, WbsNetworkStatusConnectionStateEnum} from "./webcast/reducerWbsNetworkStatus";


export const ACTION_TYPES = {


    WEBCAST_WBS_COMM_STATE_UPDATE: 'WEBCAST/WBS_COMM_STATE_UPDATE',
    WEBCAST_WBS_NETWORK_STATUS_UPDATE: 'WEBCAST/WBS_NETWORK_STATUS_UPDATE',


    WEBCAST_AUCTION_INIT: 'WEBCAST/SALE_INIT',
    WEBCAST_AUCTION_CLEAR: 'WEBCAST/SALE_CLEAR',


    WEBCAST_AUCTION_DATA_SNAPSHOT_INIT: 'WEBCAST/AUCTION_DATA_SNAPSHOT_INIT',


    WEBCAST_AUCTION_DATA_SYNC_LOTS_LIST_UPDATE: 'WEBCAST/AUCTION_DATA_SYNC_LOTS_LIST_UPDATE',

    WEBCAST_AUCTION_DATA_LOTS_DETAILS_UPDATE: 'WEBCAST/AUCTION_DATA_LOTS_DETAILS_UPDATE',  // lots details via Rails client API

    WEBCAST_STATUS_CHANGE: 'WEBCAST/STATUS_CHANGE',

    WEBCAST_CURRENT_ROUND_CREATE: 'WEBCAST/CURRENT_ROUND_CREATE',
    WEBCAST_CURRENT_ROUND_BIDDING_UPDATE: 'WEBCAST/CURRENT_ROUND_BIDDING_UPDATE',
    WEBCAST_CURRENT_ROUND_STOP: 'WEBCAST/CURRENT_ROUND_STOP',
    WEBCAST_CURRENT_ROUND_CLOSE: 'WEBCAST/CURRENT_ROUND_CLOSE',
    WEBCAST_CHOICE_LOTS_SELECTION_UPDATE: 'WEBCAST/CHOICE_LOTS_SELECTION_UPDATE',

    WEBCAST_LOTS_PASS_CLOSE: 'WEBCAST/LOTS_PASS_CLOSE',


    WEBCAST_CHAT_MESSAGE_POST: 'WEBCAST/CHAT_MESSAGE_POST', //
    WEBCAST_CHAT_ROOM_OPEN_AND_READ: 'WEBCAST/CHAT_ROOM_OPEN_AND_READ', //
    WEBCAST_CHAT_ROOM_CLOSE: 'WEBCAST/CHAT_ROOM_CLOSE', //



    //-- begin Clerk specific
    WEBCAST_CLERK_PARTICIPANTS_UPDATE: 'WEBCAST/CLERK_PARTICIPANTS_UPDATE',
    WEBCAST_CLERK_AUCTION_DATA_SYNC_CHECK_STATUS_UPDATE: 'WEBCAST_CLERK_AUCTION_DATA_SYNC_CHECK_STATUS_UPDATE',

    //-- end Clerk specific
};



export class ActionBuilder {
    static build(actionType, payload = null, meta = null) {
        return {
            type: actionType,
            payload: payload,
            meta: meta,
            error: (payload instanceof Error)
        };
    }
}




export type CurrentRoundStopActionPayloadType = {
    roundId:string,
    roundType:number,
    stopType:number,
    highestBid:Bid,
    nextRound:Round,

    choiceLotsIds:Array<number>,
    choiceUsersIds:Array<number>,

    isTransitional?:boolean,
}


export type CurrentRoundCloseActionPayloadType = {
    roundId:string,
    roundType:number,
    stopType:number,
    lotsIds:Array<number>,
    bidsHistory:Array<Bid>,
    highestBid:Bid,

    nextRound:Round,

    choiceLotsIds?:Array<number>,
    choiceUsersIds?:Array<number>,
}


export type WbsNetworkStatusUpdatePayloadType = {
    connectionState: WbsNetworkStatusConnectionStateEnum,
    roundTripMilliseconds:number|false,
}


export enum ChannelEnum {
    all = '*',
    bidding = 'bidding',
    chat = 'chat',
}


export type WbsCommStateUpdatePayloadType = {
    channel: ChannelEnum,
    newStatus: WbsCommStatesEnum,
}



const ActionsCreators = {

    webcast: {

        wbsCommStateUpdate: createAction<WbsCommStateUpdatePayloadType>(ACTION_TYPES.WEBCAST_WBS_COMM_STATE_UPDATE),

        wbsNetworkStatusUpdate: createAction<WbsNetworkStatusUpdatePayloadType>(ACTION_TYPES.WEBCAST_WBS_NETWORK_STATUS_UPDATE),


        webcastAuctionInit: createAction<WebcastAuctionConnectData_TypeDef>(ACTION_TYPES.WEBCAST_AUCTION_INIT),


        webcastAuctionClear: createAction<void>(ACTION_TYPES.WEBCAST_AUCTION_CLEAR),


        webcastAuctionDataSnapshotInit: createAction<PMAuctionDataSnapshot>(ACTION_TYPES.WEBCAST_AUCTION_DATA_SNAPSHOT_INIT),

        webcastClerkAuctionDataSyncCheckStatusUpdate: createAction<null|true|string>(ACTION_TYPES.WEBCAST_CLERK_AUCTION_DATA_SYNC_CHECK_STATUS_UPDATE),

        webcastAuctionDataSyncLotsListUpdate: createAction<Array<Lot>>(ACTION_TYPES.WEBCAST_AUCTION_DATA_SYNC_LOTS_LIST_UPDATE),

        webcastAuctionDataLotsDetailsUpdate: createAction<{lotsDetails:Array<LotDetails>,isDataSync?:boolean}>(ACTION_TYPES.WEBCAST_AUCTION_DATA_LOTS_DETAILS_UPDATE),

        webcastStatusChange: createAction<PMWebcastStatusChanged>(ACTION_TYPES.WEBCAST_STATUS_CHANGE),

        webcastClerkParticipantsUpdate: createAction<PMParticipantsUpdated>(ACTION_TYPES.WEBCAST_CLERK_PARTICIPANTS_UPDATE),

        webcastCurrentRoundBiddingUpdate: createAction<PMBiddingUpdated>(ACTION_TYPES.WEBCAST_CURRENT_ROUND_BIDDING_UPDATE),

        webcastCurrentRoundStop: createAction<CurrentRoundStopActionPayloadType>(ACTION_TYPES.WEBCAST_CURRENT_ROUND_STOP),

        webcastCurrentRoundClose: createAction<CurrentRoundCloseActionPayloadType>(ACTION_TYPES.WEBCAST_CURRENT_ROUND_CLOSE),

        webcastLotsPassClose: createAction<void>(ACTION_TYPES.WEBCAST_LOTS_PASS_CLOSE),

        choiceLotsSelectionUpdate: function webcastChoiceLotsSelectionUpdate(msgPayload:PMChoiceLotsSelected) {
            return ActionBuilder.build(ACTION_TYPES.WEBCAST_CHOICE_LOTS_SELECTION_UPDATE, msgPayload);
        },


        webcastChatMessagePost: createAction<ChatMessage>(ACTION_TYPES.WEBCAST_CHAT_MESSAGE_POST),

        webcastChatRoomOpenAndMarkAsRead: createAction<number>(ACTION_TYPES.WEBCAST_CHAT_ROOM_OPEN_AND_READ),

        webcastChatRoomClose: createAction<number>(ACTION_TYPES.WEBCAST_CHAT_ROOM_CLOSE)
    },
};

export default ActionsCreators;
