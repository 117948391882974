import React from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import AuctionBrowsableLotsPage from "./AuctionBrowsableLotsPage";
import TimedAuctionBiddingStaggeredLotsPage from "./TimedAuctionBiddingStaggeredLotsPage";
import {useSearchParams} from "react-router-dom";
import {RootState} from "@nextlot/core/rematch/store";
import {TaggedLogger} from "@nextlot/core/utilities";
import {Auction_ModelTypeDef} from "@nextlot/core/type_defs/model";
import {LotsViewModes_Enum} from "@nextlot/core/rematch/models/currentAuctionLotsModel";
import {AppController} from "@nextlot/core";

const _logger = TaggedLogger.get('AuctionLotsPageRoot');


const VIEW_MODE_CLASSES_NAMES = {
    active: 'btn-dark',
    normal: 'btn-outline-dark',
    pulsating: 'btn-outline-info btn-pulse-animation btn-border-animation',
}

export default function AuctionLotsPageRoot({preloadedLotId}:{preloadedLotId?:number|null}) {
    _logger.debug('.render()');

    const auction = useSelector<RootState, Auction_ModelTypeDef>((state: RootState) => state.currentAuction.auction);
    const auctionId = auction?.id;

    const [searchParams, setSearchParams] = useSearchParams();
    const [lotsViewMode, setLotsViewMode] = React.useState(null);

    const isFastBiddingAvailable = useSelector<RootState, boolean>((state: RootState) => state.currentAuction.isTimedFastBiddingAvailable);

    const setCurrentViewMode = React.useCallback((viewMode:LotsViewModes_Enum) => {
        if (viewMode === LotsViewModes_Enum.fastBidding) {
            setSearchParams({}); // clear any existing searchParams
        }
        storeAuctionViewMode(auctionId, viewMode);
        setLotsViewMode(viewMode);
    }, [auctionId]);

    // revert to grid when fastBidding is not available anymore (i.e. !isFastBiddingAvailable)
    React.useEffect(() => {
        if ( ! lotsViewMode) {
            // when the viewMode is NOT yet set, then check sessionStorage or default to GRID
            const initialViewMode = loadAuctionViewMode(auctionId) || AppController.instance.appConfig.site_initial_catalog_lots_view_mode;
            setCurrentViewMode(initialViewMode);
        }
        else if (lotsViewMode === LotsViewModes_Enum.fastBidding && ! isFastBiddingAvailable) {
            // if NOT fastBidding available, but viewMode is set to FastBidding, then revert back to grid
            // this happens after the auction has closed completely
            setCurrentViewMode(LotsViewModes_Enum.grid);
        }
    }, [auctionId, isFastBiddingAvailable, lotsViewMode])


    if (! lotsViewMode || ! auction) {
        // on first render, this is not set.
        // We need to avoid rendering any of the specific viewMode <***LotsPage> components (i.e. TimedAuctionBiddingStaggered or AuctionBrowsableLotsPage)
        return null;
    }

    return (
        <>
            <div className="bg-light border-bottom border-gray">
                <div className="container-xxl">
                    <div className="row">
                        <div className="col py-2 hstack gap-3 gap-lg-4">

                            <div className=''>
                                <small className='text-muted fw-light'>LOTS</small>
                                <br/>
                                <strong className='fs-4'>{auction.lotsCount}</strong>
                            </div>

                            <div className='vr' />

                            <div className="d-flex align-items-center flex-grow-1 flex-md-grow-0">

                                <button className={classNames('btn', lotsViewMode === LotsViewModes_Enum.grid ? VIEW_MODE_CLASSES_NAMES.active : VIEW_MODE_CLASSES_NAMES.normal )}
                                        disabled={lotsViewMode === LotsViewModes_Enum.grid}
                                        type="button" onClick={() => setCurrentViewMode(LotsViewModes_Enum.grid)}><i className="fas fa-th-large" /> Grid</button>

                                <button className={classNames('ms-3 btn', lotsViewMode === LotsViewModes_Enum.list ? VIEW_MODE_CLASSES_NAMES.active : VIEW_MODE_CLASSES_NAMES.normal )}
                                        disabled={lotsViewMode === LotsViewModes_Enum.list}
                                        type="button" onClick={() => setCurrentViewMode(LotsViewModes_Enum.list)}><i className="fas fa-th-list" /> List</button>

                                {
                                    ( !! isFastBiddingAvailable)
                                    &&
                                    <button className={classNames('ms-3 btn', lotsViewMode === LotsViewModes_Enum.fastBidding ? VIEW_MODE_CLASSES_NAMES.active : VIEW_MODE_CLASSES_NAMES.pulsating, 'fw-bold' )}
                                            disabled={lotsViewMode === LotsViewModes_Enum.fastBidding}
                                            type="button" onClick={() => setCurrentViewMode(LotsViewModes_Enum.fastBidding)}>

                                        <i className="fas fa-play" /> Fast Bidding

                                        <span className="border-left-to-right"></span>
                                        <span className="border-top-to-bottom"></span>
                                        <span className="border-right-to-left"></span>
                                        <span className="border-bottom-to-top"></span>

                                    </button>

                                }


                            </div>

                            <div className='vr' />

                            {
                                (auction?.timedBiddingExtensionSeconds > 0)
                                &&
                                <div className=''>
                                    <small className='text-muted fw-light'>BID EXTENSION</small>
                                    <br/>
                                    <strong className='fs-4'>{auction.timedBiddingExtensionDurationDisplayText}</strong>
                                </div>
                            }

                        </div>{/* col.hstack */}
                    </div>{/* row */}
                </div>{/* container */}
            </div>


            <div className="container-xxl">
                <div className="row">
                    {
                        (lotsViewMode === LotsViewModes_Enum.fastBidding)
                            ? <TimedAuctionBiddingStaggeredLotsPage />
                            : <AuctionBrowsableLotsPage
                                preloadedLotId={preloadedLotId}
                                currentViewMode={lotsViewMode}
                            />
                    }
                </div>
            </div>

        </>
    )
}


function loadAuctionViewMode(auctionId:number):LotsViewModes_Enum {
    return window.sessionStorage[`NL:Auction:${auctionId}:lotsViewMode`];
}

function storeAuctionViewMode(auctionId:number, viewMode:LotsViewModes_Enum) {
    window.sessionStorage[`NL:Auction:${auctionId}:lotsViewMode`] = viewMode;
}
