import React from "react";
import {AppController} from "@nextlot/core";
import {TaggedLogger} from "@nextlot/core";


const _logger = TaggedLogger.get('useClockDesynced');

const CLOCK_DESYNCED_THRESHOLD_MILLISECONDS = 60_000; // 1 minute (60 seconds)

/**
 * Fetches the server timestamp and checks if the local clock is desynced.
 * @returns boolean - Indicates if the local clock is desynced.
 */
export default function useCheckClockDesynced(): boolean {
  const [isClockDesynced, setIsClockDesynced] = React.useState<boolean>(false);

  React.useEffect(() => {
    const unsubscribe = AppController.instance.timerEventsEmitter.onServerUnixTimestampUpdate(
      (serverUnixTimestamp:number) => {
        const clockTimeDiffMs = Date.now() - (serverUnixTimestamp * 1_000);
        const desynced = Math.abs(clockTimeDiffMs) > CLOCK_DESYNCED_THRESHOLD_MILLISECONDS;

        setIsClockDesynced(desynced);

        if (desynced) {
          _logger.warn(`Clock desync detected (${Math.sign(clockTimeDiffMs) < 0 ? 'behind' : 'ahead'}): ${clockTimeDiffMs}ms / ${(clockTimeDiffMs / 1_000 / 60).toFixed(2)}s / ${(clockTimeDiffMs / 1_000 / 60 / 60).toFixed(2)}H`);
        }
      }
    );

    return () => {
      unsubscribe();
    }
  }, [])

  return isClockDesynced;
}
