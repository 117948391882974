import React from "react";
import AnimatedSkeletons from "../components/AnimatedSkeletons";
import {TaggedLogger} from "@nextlot/core";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "@nextlot/core/rematch/store";
import classNames from "classnames";
import {LotsViewModes_Enum} from "@nextlot/core/rematch/models/currentAuctionLotsModel";
import LotCard, {BiddingContextViewEnum} from "../components/LotCard";
import AppController from "@nextlot/core/AppController";
import getCurrentBidder from "@nextlot/core/hooks/getCurrentBidder";

const LOCAL_STORAGE_KEY_INFO_ALERT_HIDDEN:string = 'NL:MyWatchlist:infoAlertHidden';

const _logger = TaggedLogger.get("MyWatchlistPage");

export function MyWatchlistPage() {
    _logger.debug('.render()');

    const currentBidderId = getCurrentBidder()?.id;
    const dispatch = useDispatch<Dispatch>();

    const [lotsViewMode, setLotsViewMode] = React.useState(LotsViewModes_Enum.grid);

    const watchlistLotsActive = useSelector((state: RootState) => state.watchlist.lotsListActive);
    const watchlistLotsClosed = useSelector((state: RootState) => state.watchlist.lotsListClosed);

    const hasMoreLotsToFetch = useSelector((state: RootState) => (state.watchlist.pagesCount > state.watchlist.pageNumber));

    const isFetching = useSelector((state: RootState) => state.watchlist.isFetching);
    const hasFetchError = useSelector((state: RootState) => state.watchlist.hasFetchError);


    React.useEffect(() => {
        if (! currentBidderId) {
            return;
        }

        dispatch.watchlist.asyncInitialLoadWatchedLots();

        const unbindListener = AppController.instance.remoteEventsEmitter.onMyLotsInterestChange((data) => {
            dispatch.watchlist.asyncFetchAndUpdateLotChangedInterest(data);
        });

        return () => {
            unbindListener();
        }
    }, [currentBidderId]);


    const uniqAuctionsIds = [ ... new Set(watchlistLotsActive?.map((lot) => lot.auctionId))].sort();
    const depAuctionsIds = JSON.stringify(uniqAuctionsIds);



    React.useEffect(() => {
        const auctionsIds:Array<number> = JSON.parse(depAuctionsIds);
        const unbindAuctionsChannels = auctionsIds.map((auctionId) => {
            // _logger.debug('>>useEffect[auctionsIds]>>> BINDING auctionId:', auctionId);
            return AppController.instance.remoteEventsEmitter.onAuctionLotsChange(auctionId, ({lotId}) => {
                dispatch.currentAuctionLots.asyncFetchAndUpdateRunningLotWithSideEffects({ auctionId, lotId });
            });
        });

        return () => {
            unbindAuctionsChannels.forEach((unbindFn) => {
                // _logger.debug('>>useEffect[auctionsIds]>>> UN-BINDING auctionId:');
                unbindFn();
            })
        }
    }, [depAuctionsIds]);



    const handleClick_loadMore = (evt) => {
        dispatch.watchlist.asyncLoadMoreWatchedLots();
    }




    const localStoreIsInfoAlertVisible = ! localStorage.getItem(LOCAL_STORAGE_KEY_INFO_ALERT_HIDDEN);
    const [isInfoAlertVisible, setIsInfoAlertVisible] = React.useState(localStoreIsInfoAlertVisible);
    const handleClick_toggleInfoAlert = (visible:boolean) => (_evt) => {
        setIsInfoAlertVisible(visible);
        if (! visible) {
            localStorage.setItem(LOCAL_STORAGE_KEY_INFO_ALERT_HIDDEN, JSON.stringify(true));
        }
    }


    return (
        <>
            <div className="bg-light border-bottom border-gray">
                <div className="container-xxl">
                    <div className="row py-2 row-cols-2 justify-content-between">
                        <div className="col-auto d-flex flex-column flex-sm-row align-items-baseline">
                            <div className='fs-4 fw-bold'>My Watchlist</div>
                            <div className='ms-sm-3 small text-muted fw-light'>on currently running auctions</div>
                        </div>

                        {
                            ( ! isInfoAlertVisible)
                            &&
                            <div className="col-auto align-items-center">
                                <button className='btn btn-sm btn-outline-info' onClick={handleClick_toggleInfoAlert(true)}>
                                    <i className='fa-solid fa-circle-info fa-fw' />
                                </button>
                            </div>
                        }
                    </div>

                    {
                        isInfoAlertVisible
                        &&
                        <div className='row'>
                            <div className='col'>
                                <div className='alert alert-info d-flex align-items-center flex-column flex-sm-row'>
                                    <span><i className='me-2 fa-solid fa-circle-info' />
                                        Lots that are not closed are sorted by their remaining time to close.
                                        Closed lots are moved at the bottom of the page, under the "Closed lots" section.
                                    </span>
                                    <button type="button" className="ms-0 mt-3 ms-sm-3 mt-sm-0 btn btn-sm btn-info" onClick={handleClick_toggleInfoAlert(false)}>Dismiss</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>


            <div className="container-xxl">
                <div className="row py-4">
                    <div className="col mb-4">
                        {
                            watchlistLotsActive?.length
                                ? <div className={classNames('lots-view', { 'lots-view--grid': lotsViewMode === LotsViewModes_Enum.grid, 'lots-view--list': lotsViewMode === LotsViewModes_Enum.list })}>
                                    {
                                        watchlistLotsActive
                                            .map((lot, idx) => (
                                                <LotCard
                                                    key={lot.id}
                                                    auction={lot.auction}
                                                    lot={lot}
                                                    biddingContextView={BiddingContextViewEnum.watchlist}
                                                />))
                                    }
                                </div>
                                : (
                                    isFetching
                                        ? <AnimatedSkeletons.LotCardSkeleton />
                                        : (
                                            hasFetchError
                                                ? <div className='py-3'>
                                                    <div className='alert alert-danger'>Failed to load Watchlist: {hasFetchError}</div>
                                                </div>
                                                : <div className='py-3'>
                                                    <div className='alert alert-warning'>There are no lots in your Watchlist.</div>
                                                </div>
                                        )

                                )


                        }
                    </div>
                </div>

                {
                    isFetching
                        ? <div className='py-3 text-center'>Loading ...</div>
                        : (
                            hasMoreLotsToFetch // if there are more lots to fetch, then show the 'Load more' button
                            && <div className='py-5 d-flex flex-column align-items-center'>
                                <p>&nbsp;</p>
                                <button type="button" onClick={handleClick_loadMore} className="btn btn-outline-secondary">Load more lots &nbsp; <i className="fa-regular fa-down-from-dotted-line" /></button>
                            </div>
                        )

                }

                <hr />

                <div className="row py-4">
                    <div className="col">
                        <h3>Closed lots</h3>
                    </div>
                </div>

                <div className="row py-4">
                    <div className="col mb-4">
                        {
                            (!! watchlistLotsClosed?.length)
                            &&
                            <div className={classNames('lots-view', { 'lots-view--grid': lotsViewMode === LotsViewModes_Enum.grid, 'lots-view--list': lotsViewMode === LotsViewModes_Enum.list })}>
                                {
                                    watchlistLotsClosed
                                        .map((lot, idx) => (
                                            <LotCard
                                                key={lot.id}
                                                auction={lot.auction}
                                                lot={lot}
                                                biddingContextView={BiddingContextViewEnum.watchlist}
                                            />))
                                }
                            </div>
                        }

                    </div>
                </div>

            </div>

        </>
    )
}

