import ActionsCreators, {CurrentRoundCloseActionPayloadType} from "../actions";
import {Bid, Lot, LotDetails, PMAuctionDataSnapshot, PMLotsPassClosed, Round} from "../../data/data_types_definitions";
import {decorateBid, LotStateEnum, RoundStopTypeEnum, RoundTypeEnum} from "../../data/WebcastDataDomain";
import {createReducer} from "@reduxjs/toolkit";

export type ReduxWebcastAuctionDataType = {
    auctionId: number,
    auctionName: string,
    lots: Array<Lot>,
    lotsMapById: Map<number, Lot>,
    closedRounds: Array<Round>,

    // clerk only
    remoteLotsCacheKeyOutOfSync?: string|null // 'list'|'details'|'media_files'|null
}



const initialState:ReduxWebcastAuctionDataType = {
    auctionId: null,
    auctionName: null,
    lots: [],
    lotsMapById: new Map<number, Lot>(),
    closedRounds: [],
};


// png: 150x100 bg:#ccc round corners
const LOT_IMAGE_PLACEHOLDER_SRC = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAABkCAQAAADOUgF7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfjBBkVGwepKkL7AAABkElEQVR42u3UMUpDQRhF4X9GIQHRbMDaQlSwUNOmDG4hfbASV+MCzBasbcVoIaayjgswpniGwNhb5VSTB+dbweUUN0VEfHYW4xiV47QX+qes0qxMlveDJiJFPB/uPKaz2qO23Gw97M/TZ+f7xVQb+Di4yIuxqTZyurjJMaq9oi3KKE2X3vqGmmyqjXVz7QVtYizAWICxAGMBxgKMBRgLMBZgLMBYgLEAYwHGAowFGAswFmAswFiAsQBjAcYCjAUYCzAWYCzAWICxAGMBxgKMBRgLMBZgLMBYgLEAYwHGAowFGAswFmAswFiAsQBjAcYCjAUYCzAWYCzAWICxAGMBxgKMBRgLMBZgLMBYgLEAYwHGAowFGAswFmAswFiAsQBjAcYCjAUYCzAWYCzAWICxAGMBxgKMBRgLMBZgLMBYgLEAYwHGAowF5LKqPaE1mpxmtTe0RZnlMqk9oi3SQ3rq7k/jpPaQ7Vfee1d50KyH8VF7yrYr77vXR785oj//uYy78hZN7UlbqSmvcdu7Ov+K+ANZ4DibiMdZIAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNC0yNlQwNjoyNzowNyswOTowMBgt5a8AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDQtMjZUMDY6Mjc6MDcrMDk6MDBpcF0TAAAAAElFTkSuQmCC';




/**
 * combined as `webcastAuctionData`
 *
 *
 *
 * ===
 * action: WEBCAST_AUCTION_INIT
 * payload: {
 *      auctionId: number,
 *      auctionName: number,
 * }
 *
 *
 *
 * ===
 * action: WEBCAST_AUCTION_CLEAR
 * payload: null
 *
 *
 *
 * ===
 * action: WEBCAST_AUCTION_DATA_SNAPSHOT_INIT
 * payload: <PMAuctionDataSnapshot> {
 *     lots: Array<Lot>,
 *     remoteLotsCacheKeyOutOfSync: string,
 *     closedRounds: [],
 *     currentRound: ?Round
 * }
 *
 *
 * ==
 * action: WEBCAST_CLERK_AUCTION_DATA_SYNC_CHECK_STATUS_UPDATE  // used by "sync lots from Rails", update clerk-only info
 * payload: null|'list'|'details'|'media_files' // the outOfSync cache key
 *
 *
 * ==
 * action: WEBCAST_AUCTION_DATA_SYNC_LOTS_LIST_UPDATE  // used by "sync lots from Rails"
 * payload: Array<Lot> // new list of lots
 *
 * ==
 * action: WEBCAST_AUCTION_DATA_LOTS_DETAILS_UPDATE  // lots description, images
 * payload: Array<LotDetails>
 *
 *
 * ===
 * action: WEBCAST_CURRENT_ROUND_CLOSE
 * payload: <CurrentRoundCloseActionPayloadType> {
 *      stopType: number
 * }
 *
 *
 * ===
 * action: WEBCAST_LOTS_PASS_CLOSE
 * payload: <PMLotsPassClosed> {
 *      lotsIds: Array<number>
 * }
 *
 *
 *
 *
 * @param state
 * @param action
 * @returns {{
 *      lots: Array<Lot>
 *      lotsMapById: Map<number, Lot>
 *      closedRounds: Array<Round>
 * }}
 */
const reducerWebcastAuctionData = createReducer<ReduxWebcastAuctionDataType>(initialState, (builder) => {

    builder.addCase(ActionsCreators.webcast.webcastAuctionClear, (state, action) => {
        return initialState;
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionInit, (state, action) => {
        state.auctionId = action.payload.auctionId;
        state.auctionName = action.payload.auctionName;
        state.lots = [];
        state.lotsMapById = new Map<number, Lot>();
        state.closedRounds = [];
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSnapshotInit, (state, action) => {
        const payload:PMAuctionDataSnapshot = action.payload;
        const lots = payload.lots.map((lot) => {
            return {
                name: '...',
                defaultImageSmUrl: LOT_IMAGE_PLACEHOLDER_SRC,
                ... lot
            }
        });

        state.auctionId = payload.auctionId;
        state.auctionName = payload.auctionName;
        state.lots = lots;
        state.lotsMapById = new Map(lots.map((lot) => [lot.id, lot]));
        state.closedRounds = payload.closedRounds.map((payloadRound:Round):Round => {
            const bidsHistoryLength:number = (payloadRound.bidsHistory && payloadRound.bidsHistory.length) || 0;
            const highestBid:Bid = bidsHistoryLength > 0 ? payloadRound.bidsHistory[bidsHistoryLength-1] : null;
            return {
                ... payloadRound,
                highestBid: decorateBid(highestBid),
            }
        });

        state.remoteLotsCacheKeyOutOfSync = payload.remoteLotsCacheKeyOutOfSync;
    });


    builder.addCase(ActionsCreators.webcast.webcastClerkAuctionDataSyncCheckStatusUpdate, (state, action) => {
        if (action.payload === true) {
            // sync was just applied
            state.remoteLotsCacheKeyOutOfSync = null;
        }
        else {
            // sync check result
            state.remoteLotsCacheKeyOutOfSync = action.payload;
        }
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataSyncLotsListUpdate, (state, action) => {
        const syncedLots = action.payload.map((payloadLot:Lot) => {
            let syncedLot:Lot;

            const existingLot:Lot = state.lotsMapById.get(payloadLot.id);
            if (existingLot) {
                // existing lot
                syncedLot = {
                    ... existingLot,
                    orderIdx: payloadLot.orderIdx,
                }
            }
            else {
                // new lot
                syncedLot = {
                    name: '...',
                    defaultImageSmUrl: LOT_IMAGE_PLACEHOLDER_SRC,
                    ... payloadLot
                }
            }

            return syncedLot;
        });

        state.lots = syncedLots;
        state.lotsMapById = new Map(syncedLots.map((lot) => [lot.id, lot]));
    });


    builder.addCase(ActionsCreators.webcast.webcastAuctionDataLotsDetailsUpdate, (state, action) => {
        const lotsUpdated = state.lots.map((lot) => {
            let lotExtra:LotDetails = action.payload.lotsDetails.find((ld) => lot.id === ld.id);
            // WARNING: `lotExtra` may be null, when lots have been deleted on Rails after WBS prepare
            lotExtra = lotExtra || { name: 'REMOVED FROM AUCTION' };
            const firstLotImage = lotExtra.images && lotExtra.images[0];
            return {
                ... lot,
                ... lotExtra,
                defaultImageSmUrl: firstLotImage && firstLotImage.smUrl || LOT_IMAGE_PLACEHOLDER_SRC,
            }
        });

        state.lots = lotsUpdated;
        state.lotsMapById = new Map(lotsUpdated.map((lot) => [lot.id, lot]));
    });


    builder.addCase(ActionsCreators.webcast.webcastCurrentRoundClose, (state, action) => {
        return handleAction_CURRENT_ROUND_CLOSE(state, action.payload);
    });


    builder.addCase(ActionsCreators.webcast.webcastLotsPassClose, (state, action) => {
        return handleAction_LOTS_PASS_CLOSE(state, action.payload);
    });


    builder.addDefaultCase((state, action) => {return state});

});

export default reducerWebcastAuctionData;



const ROUND_STOP_TO_LOT_STATE_MAPPING = new Map([
    [RoundStopTypeEnum.CANCEL, LotStateEnum.OPEN],
    [RoundStopTypeEnum.NO_SALE, LotStateEnum.CLOSED_NO_SALE],
    [RoundStopTypeEnum.PENDING, LotStateEnum.CLOSED_PENDING],
    [RoundStopTypeEnum.SOLD, LotStateEnum.CLOSED_SOLD],
]);




function handleAction_CURRENT_ROUND_CLOSE(state:ReduxWebcastAuctionDataType, payload:CurrentRoundCloseActionPayloadType):ReduxWebcastAuctionDataType {

    if (payload.stopType === RoundStopTypeEnum.CANCEL) {
        // do nothing if this is a canceled round
        return state;
    }


    const lotCloseState = ROUND_STOP_TO_LOT_STATE_MAPPING.get(payload.stopType);

    const closedRounds:Array<Round> = [];
    let closedLotsIds:Array<number> = [];

    if (payload.roundType === RoundTypeEnum.GROUP_CHOICE || payload.roundType === RoundTypeEnum.GROUP_CHOICE_REOPEN) {

        if (payload.stopType === RoundStopTypeEnum.NO_SALE) {
            closedLotsIds = payload.lotsIds;
        }
        else {
            // stopType is SOLD|PENDING

            const userLotsSelection: Map<number, Array<number>> = new Map();

            payload.choiceLotsIds && payload.choiceLotsIds.forEach((lotId, idx) => {
                const userId = payload.choiceUsersIds[idx];
                if (userId === 0) {
                    // participantId==0 means unselected; skip lots that were mark unselected
                    return
                }

                let userSelection: Array<number> = userLotsSelection.get(userId);
                if (!userSelection) {
                    userSelection = [];
                    userLotsSelection.set(userId, userSelection);
                }
                userSelection.push(lotId);

                closedLotsIds.push(lotId);
            });

            userLotsSelection.forEach((selectedLotsIds, userId) => {
                const highestBid: Bid = {...payload.highestBid, userId};
                // hack: we need bidderNumber and bidderLabel if we want to display this on the bidder side

                closedRounds.push({
                    roundId: payload.roundId,
                    roundType: payload.roundType,
                    lotsIds: selectedLotsIds,
                    stopType: payload.stopType,
                    bidsHistory: [...payload.bidsHistory, highestBid],
                    highestBid: decorateBid(highestBid),
                });
            });
        }
    }
    else {

        // not a CHOICE|CHOICE_REOPEN  => just one round has closed

        closedRounds.push({
            roundId: payload.roundId,
            roundType: payload.roundType,
            lotsIds: payload.lotsIds,
            stopType: payload.stopType,
            bidsHistory: payload.bidsHistory,
            highestBid: decorateBid(payload.highestBid),
        });

        closedLotsIds = payload.lotsIds;
    }

    let stateClosedRounds: Array<Round> = state.closedRounds.map( (closedRound:Round): Round => {
        return {
            ...closedRound,
            lotsIds: closedRound.lotsIds.filter(lotId => !closedLotsIds.includes(lotId))
        }
    }).filter(r => r.lotsIds.length > 0);


    const lots = state.lots.map((lot) => {
        if (closedLotsIds.includes(lot.id)) {
            return {
                ... lot,
                state: lotCloseState
            }
        }
        else {
            // not part of closedLots list, then leave untouched
            return lot;
        }
    });



    return {
        ...state,
        lots: lots,
        lotsMapById: new Map(lots.map((lot) => [lot.id, lot])),
        closedRounds: [ ...stateClosedRounds, ...closedRounds ]
    }
}






function handleAction_LOTS_PASS_CLOSE(state:ReduxWebcastAuctionDataType, payload:PMLotsPassClosed):ReduxWebcastAuctionDataType {

    if (! payload.noSaleRound) {
        // do nothing
        return state;
    }

    const closedRound:Round = payload.noSaleRound;

    const lots = state.lots.map((lot) => {
        if (closedRound.lotsIds.includes(lot.id)) {
            return {
                ... lot,
                state: LotStateEnum.CLOSED_NO_SALE
            }
        }
        else {
            // not part of lots to close, then leave untouched
            return lot;
        }
    });

    return {
        ...state,
        lots: lots,
        lotsMapById: new Map(lots.map((lot) => [lot.id, lot])),
        closedRounds: [ closedRound, ... state.closedRounds ],
    }
}

