import URI from "urijs";
import {LotDetails, PMAuctionDataSnapshot, PMAuctionDataSyncStatusChanged, PMRoundStopped, WBSWebsocketMessage} from "../data/data_types_definitions";
import {TaggedLogger} from '../../utilities';
import ActionsCreators, {
    ACTION_TYPES,
    ActionBuilder,
    ChannelEnum,
    CurrentRoundCloseActionPayloadType,
    CurrentRoundStopActionPayloadType
} from "../redux/actions";

import {RoundStopTypeEnum} from "../data/WebcastDataDomain";
import DataStore, {ReduxStateType} from "./DataStore";
import {WbsCommStatesEnum, WbsNetworkStatusConnectionStateEnum} from "../redux/webcast/reducerWbsNetworkStatus";
import {ReduxActiveWebcastSubStateType} from "../redux/reducerActiveWebcast";
import {Store} from "redux";
import {getMoneyHelperForCurrencyCode} from "../../services/MoneyHelper";
import {AppRemoteDataHandler} from "../../services/AppRemoteDataHandler";
import {WebcastScreenTypeCodesEnum} from "../../StaticModelsAndConstants";


type WebcastAuctionHandlerControllerType = {
    sendAuctionDataSyncApply(): void,

    sendBidPlace(roundId: string, value: number): void,
    sendChoiceLotsSelection(roundId: string, lotsIds:Array<number>):void,

    sendWebcastStatusChange(statusData: any): void,
    sendRoundCreate(roundType: number, lotsIds: Array<number>): void,
    sendRoundStop(roundId: string, stopType: number): void,

    sendAskingBidUpdate(roundId: string, askingBidValue: number, bidIncrement?: number): void,
    sendBidRevise(roundId: string, reviseType: number, bidValue: number): void,
    sendPreBidPlace(roundId: string, bidderUserId:number, value: number): void,

    sendChoiceLotsAssignment(roundId: string, lotsIds:Array<number>, usersIds:Array<number>, nextRoundType:number): void,

    sendLotsPass(lotsIds: Array<number>): void,

    sendChatMessage(roomId: number, body: string): void,

    openChatRoomAndMarkAsRead(roomId: number): void,
    closeChatRoom(roomId: number): void,

    sendStreamingHostUpdate(remoteUserUid:number|string, { hasAudio, hasVideo }:{ hasAudio:boolean, hasVideo:boolean }): void,
}


export enum ParticipantRolesEnum {
    clerk = 'clerk',
    auctioneer = 'auctioneer',
    bidder = 'bidder',
    viewer = 'viewer',
    onsite = 'onsite',
}

export type WebcastAuctionConnectData_TypeDef = {
    auctionId: number,
    auctionName?: string,

    participantId: number,
    participantRole: ParticipantRolesEnum,

    bidderNumber: string,
    bidderLabel: string,

    wbsHostname: string,
    wbsConnectToken: string,

    streamingAgoraAppId: string,
    streamingAgoraChannelName: string,
    streamingAgoraToken: string,

    currencyCode:string,

    defaultClerkIncrements:Array<number>,

    screenType?: WebcastScreenTypeCodesEnum,
};



export const ServerPushedMessageTypeEnum = Object.freeze({
    AuctionDataSnapshot: 'AuctionDataSnapshot',
    AuctionDataSyncStatusChanged: 'AuctionDataSyncStatusChanged',

    ParticipantsUpdated: 'ParticipantsUpdated', // received by clerk only

    WebcastStatusChanged: 'WebcastStatusChanged',
    RoundCreated: 'RoundCreated',
    BiddingUpdated: 'BiddingUpdated',
    RoundStopped: 'RoundStopped',
    LotsPassClosed: 'LotsPassClosed',

    ChoiceLotsSelected: 'ChoiceLotsSelected',

    ChatMessagePosted: 'ChatMessagePosted',

    StreamingHostUpdated: 'StreamingHostUpdated',


    ReplyToInvalidRequest: 'ReplyToInvalidRequest',
});


export const ClientMessageTypeEnum = Object.freeze({
    BidPlace: 'BidPlace',
    ChoiceLotsSelect: 'ChoiceLotsSelect',
    ChatMessagePost: 'ChatMessagePost',

    // Clerk ONLY below
    AuctionDataSyncApply: 'AuctionDataSyncApply',
    WebcastStatusChange: 'WebcastStatusChange',
    RoundCreate: 'RoundCreate',
    AskingBidUpdate: 'AskingBidUpdate',
    PreBidPlace: 'PreBidPlace',
    BidRevise: 'BidRevise',
    RoundStop: 'RoundStop',
    ChoiceLotsAssign: 'ChoiceLotsAssign',
    LotsPassClose: 'LotsPassClose',

    StreamingHostUpdate: 'StreamingHostUpdate',
});




const silentClientMsgTypes = [ ClientMessageTypeEnum.StreamingHostUpdate ];

const chatClientMsgTypes = [ ClientMessageTypeEnum.ChatMessagePost ];
const clientMsgTypesMapArray = [];
// add the 'chat' msgTypes pairs
chatClientMsgTypes.forEach(msgType => { clientMsgTypesMapArray.push([msgType, ChannelEnum.chat])});
Object.getOwnPropertyNames(ClientMessageTypeEnum)
    .map(objKeyName => ClientMessageTypeEnum[objKeyName])
    .filter(msgType => ((chatClientMsgTypes.indexOf(msgType) === -1) && (silentClientMsgTypes.indexOf(msgType) === -1))) // exclude 'chat' and "silent" msgTypes
    .forEach(msgType => { clientMsgTypesMapArray.push([msgType, ChannelEnum.bidding])});

export const WbsCommChannelForClientMessageTypeMap = new Map<string, ChannelEnum>(clientMsgTypesMapArray);



const serverMsgTypesMapArray = [];
const silentServerMsgTypes = [ ClientMessageTypeEnum.StreamingHostUpdate ];
const chatServerMsgTypes = [ ServerPushedMessageTypeEnum.ChatMessagePosted ];
// add the 'chat' msgTypes pairs
chatServerMsgTypes.forEach(msgType => { serverMsgTypesMapArray.push([msgType, ChannelEnum.chat])});
// add the 'bidding' msgTypes pairs
Object.getOwnPropertyNames(ServerPushedMessageTypeEnum)
    .map(objKeyName => ServerPushedMessageTypeEnum[objKeyName])
    .filter(msgType => ((chatServerMsgTypes.indexOf(msgType) === -1) && (silentServerMsgTypes.indexOf(msgType)))) // exclude 'chat' and "silent" msgTypes
    .forEach(msgType => { serverMsgTypesMapArray.push([msgType, ChannelEnum.bidding])});

export const WbsCommChannelForServerPushedMessageTypeMap = new Map<string, ChannelEnum>(serverMsgTypesMapArray);
// exception: all channels are affected by these ServerMessages
WbsCommChannelForServerPushedMessageTypeMap.set(ServerPushedMessageTypeEnum.AuctionDataSnapshot, ChannelEnum.all);
WbsCommChannelForServerPushedMessageTypeMap.set(ServerPushedMessageTypeEnum.ReplyToInvalidRequest, ChannelEnum.all);






let _currentWebcastAuctionHandler:WebcastAuctionHandler;
let _reduxStore:Store;


const _logger = TaggedLogger.get('AuctionsManager');


export default class AuctionsManager {


    static init(reduxStore:Store):void {
        _reduxStore = reduxStore;
    }




    static leaveWebcast():Promise<void> {
        if (_currentWebcastAuctionHandler) {
            _currentWebcastAuctionHandler._destroy();
            _currentWebcastAuctionHandler = null;
        }
        return Promise.resolve();
    }








    /**
     * @param auctionConnectData
     * @returns {WebcastAuctionHandler}
     */
    static createWebcastAuctionHandler(auctionConnectData:WebcastAuctionConnectData_TypeDef):WebcastAuctionHandler {

        // cleanup previous handler to avoid mem-leaks
        if (_currentWebcastAuctionHandler) {
            throw new Error('.createWebcastAuctionHandler FAILED: existing _currentWebcastAuctionHandler')
            // _logger.warn('.createWebcastAuctionHandler: destroying existing webcastAuctionHandler. This is a possible bug! You need to call `AuctionsManager.leaveWebcast()` to cleanup nicely');
            // _currentWebcastAuctionHandler._destroy();
            // _currentWebcastAuctionHandler = null;
        }


        // update redux state with this auction (will clear/destroy any data from previous auction handler, if existed)
        _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionInit(auctionConnectData));

        _currentWebcastAuctionHandler = new WebcastAuctionHandler(auctionConnectData);

        return _currentWebcastAuctionHandler;
    }


    static get currentWebcastAuctionHandler():WebcastAuctionHandler {
        return _currentWebcastAuctionHandler;
    }

}


// DEBUG
// window._dev_AuctionsManager = AuctionsManager;


export type MediaStreamingHostsUpdateType = {
    wbsAudioUid?:number|string,
    wbsVideoUid?:number|string,
}

const _loggerMSH = TaggedLogger.get('MediaStreamingHandler');


class MediaStreamingHandler {

    listenerFn:(MediaStreamingHostsUpdateType)=>void = null;
    pendingUpdate:MediaStreamingHostsUpdateType = null;


    onMediaStreamingHostsUpdated(listener:(update:MediaStreamingHostsUpdateType)=>void):()=>void {

        if (this.listenerFn) {
            throw 'MediaStreamingHandler.onMediaStreamingHostsUpdated: listener already registered';
        }

        // register listener
        this.listenerFn = listener;

        // fire any pending change if exists
        if (this.pendingUpdate) {
            this.listenerFn(this.pendingUpdate);
        }

        return () => {
            this.listenerFn = null;
        }
    }



    $update(update:MediaStreamingHostsUpdateType) {
        _loggerMSH.debug('.$update(): ', update);

        // if there is a listener registered
        if (this.listenerFn) {
            // trigger the registered listener
            this.listenerFn(update);
        }
        else {
            // save the last unsent change event
            this.pendingUpdate = update;
        }
    }


    $destroy() {
        _loggerMSH.debug('.$destroy()');

        this.listenerFn && this.listenerFn(null);
        // remove listener
        this.listenerFn = null;
    }
}




const _loggerWSH = TaggedLogger.get('AuctionsManager::WebcastAuctionHandler');



/**
 * @private
 */
export class WebcastAuctionHandler {

    public readonly auctionConnectData:WebcastAuctionConnectData_TypeDef;
    public readonly myUserId:number;
    wsConn:WebSocket;
    connectedWS = false;
    private _controller:WebcastAuctionHandlerControllerType;
    _heartbeatInterval = null;
    _fetchAuctionDataDetailsCachedResult:Array<LotDetails> = null;
    _fetchAuctionDataDetailsPromise:Promise<Array<LotDetails>> = null;

    _heartbeatPongReceived:boolean = false;
    _heartbeatPingSentTimestamp:number = 0;

    private readonly _mediaStreamingHandler:MediaStreamingHandler;

    private _remoteDataHandler: AppRemoteDataHandler;


    constructor(auctionConnectData:WebcastAuctionConnectData_TypeDef) {
        this.auctionConnectData = auctionConnectData;
        this.myUserId = auctionConnectData.participantId;
        this._mediaStreamingHandler = new MediaStreamingHandler();
    }



    /**
     * This is the MAIN "igniter" for all events triggered during a Webcast auction.
     *
     * It starts 2 connections:
     * - websockets to WBS
     * - http fetch to FrontendAPI (Rails)
     *
     * @returns {Promise<Boolean>} when connection is open, resolves `true`; if error, rejects
     */
    public async connect():Promise<boolean> {

        const wsUrlEndpoint = new URI(`wss://${this.auctionConnectData.wbsHostname}/ws/${this.auctionConnectData.participantRole}`)
            .query({
                aid: this.auctionConnectData.auctionId,
                uid: this.auctionConnectData.participantId,
                token: this.auctionConnectData.wbsConnectToken
            })
            .toString();


        _loggerWSH.info('.connect: ', wsUrlEndpoint);


        // initialize the controller we'll use to send commands (send WBS messages, misc actions, etc)
        this._controller = {

            sendWebcastStatusChange: (statusData):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.WebcastStatusChange, statusData);
            },

            sendAuctionDataSyncApply: ():void => {
                this._sendWbsMessage(ClientMessageTypeEnum.AuctionDataSyncApply);
            },


            sendRoundCreate: (roundType, lotsIds):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.RoundCreate, { lotsIds, roundType });
            },

            sendRoundStop: (roundId:string, stopType:number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.RoundStop, { roundId, stopType });
            },

            sendAskingBidUpdate: (roundId:string, askingBidValue:number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.AskingBidUpdate, { roundId, askingBidValue })
            },

            sendBidRevise: (roundId:string, reviseType:number, bidValue:number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.BidRevise, { roundId, reviseType, bidValue })
            },


            sendBidPlace: (roundId:string, value:number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.BidPlace, { roundId, value });
            },

            sendPreBidPlace: (roundId: string, bidderUserId: number, value: number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.PreBidPlace, { roundId, bidderUserId, value });
            },

            sendChoiceLotsSelection: (roundId: string, lotsIds:Array<number>):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.ChoiceLotsSelect, { roundId, lotsIds });
            },

            sendChoiceLotsAssignment: (roundId: string, lotsIds:Array<number>, usersIds:Array<number>, nextRoundType:number):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.ChoiceLotsAssign, { roundId, lotsIds, usersIds, nextRoundType });
            },


            sendLotsPass: (lotsIds: Array<number>):void => {
                this._sendWbsMessage(ClientMessageTypeEnum.LotsPassClose, { lotsIds });
            },

            sendChatMessage: (roomId:number, body:string):void => {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastChatRoomOpenAndMarkAsRead(roomId));
                this._sendWbsMessage(ClientMessageTypeEnum.ChatMessagePost, { roomId, body });
            },

            sendStreamingHostUpdate: (streamId:number, { hasAudio, hasVideo }):void => {
                const msgData:{audioUid?:number, videoUid?:number} = {};
                if (hasAudio) {
                    msgData.audioUid = streamId;
                }
                if (hasVideo) {
                    msgData.videoUid = streamId;
                }

                this._sendWbsMessage(ClientMessageTypeEnum.StreamingHostUpdate, msgData);
            },

            openChatRoomAndMarkAsRead: (roomId:number):void => {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastChatRoomOpenAndMarkAsRead(roomId));
            },

            closeChatRoom: (roomId:number):void => {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastChatRoomClose(roomId));
            }
        };


        this._remoteDataHandler = new AppRemoteDataHandler({ siteId: DataStore.config.site_id, frontendApiBaseUrl: DataStore.config.frontend_api_base_url });


        return new Promise((resolve, reject) => {
            let promiseResolved = 0;

            try {
                this.wsConn = new WebSocket(wsUrlEndpoint);
            }
            catch (ex) {
                promiseResolved = -11;  // error code `1x` are for the WS conn
                reject(ex.toString());
                return; // stop here
            }

            this.wsConn.binaryType = "arraybuffer";

            this.wsConn.addEventListener('open', (evt: Event) => {
                if (promiseResolved !== 0) {
                    _loggerWSH.warn('wsConn>open: connected, BUT previous error encountered! promiseResolved:', promiseResolved);
                    this.wsConn.close(4002);
                    return;
                }

                _loggerWSH.debug('wsConn>open: readyState:', this.wsConn.readyState, evt);

                this.connectedWS = true;
                promiseResolved = 11;

                this._heartbeatPongReceived = true;
                this._heartbeatPingSentTimestamp = Date.now(); // initialize

                this._heartbeatInterval = setInterval(() => {
                    if (this._heartbeatPongReceived) {
                        this._heartbeatPongReceived = false;
                        this._heartbeatPingSentTimestamp = Date.now();
                        this.wsConn && this.wsConn.send('[1]'); // send ping
                    }
                    else {
                        _loggerWSH.warn('wsConn>open: heartbeat: pong-reply timeout! closing with code 4006');
                        this._destroy(4006);
                    }
                }, 25 * 1000); // 25 seconds : https://tools.ietf.org/html/rfc6455#section-5.5.2

                _reduxStore.dispatch(ActionsCreators.webcast.wbsNetworkStatusUpdate({ connectionState: WbsNetworkStatusConnectionStateEnum.connected, roundTripMilliseconds: 1 }));

                resolve(true);
            })


            this.wsConn.addEventListener('close', (evt: CloseEvent) => {
                _loggerWSH.debug('wsConn>close: ', evt);
                this.wsConn = null; // if closed, then never recover, remove reference to avoid mem-leak
                this.connectedWS = false;
                if (this._heartbeatInterval) {
                    clearInterval(this._heartbeatInterval);
                    this._heartbeatInterval = null;
                }

                _reduxStore.dispatch(ActionsCreators.webcast.wbsNetworkStatusUpdate({ connectionState: WbsNetworkStatusConnectionStateEnum.disconnected, roundTripMilliseconds: 0 }));

                this._handleWSClose();
            })


            this.wsConn.addEventListener('message', async (evt) => {
                // const dataStr = ab2str(evt.data);
                // const data = JSON.parse(dataStr);
                const msgStr = evt.data;

                // handle 'pong'
                if (msgStr && msgStr === '[2]') {
                    this._heartbeatPongReceived = true;
                    _reduxStore.dispatch(ActionsCreators.webcast.wbsNetworkStatusUpdate({ connectionState: WbsNetworkStatusConnectionStateEnum.connected, roundTripMilliseconds: Date.now() - this._heartbeatPingSentTimestamp }));
                    return;
                }

                const msg: WBSWebsocketMessage = JSON.parse(msgStr);
                _loggerWSH.debug('wsConn>message: ', msg);
                const msgType = msg[0];
                const msgPayload = msg[1];
                await this._handleOnWbsMessageReceived(msgType, msgPayload);
            })


            this.wsConn.addEventListener('error', (evt) => {
                _loggerWSH.debug('wsConn>error: ', 'readyState:', this.wsConn.readyState, 'event: ', evt);
                this._destroy(4001);
                if (promiseResolved === 0) {
                    promiseResolved = -12;
                    reject(evt);
                }
            })



            // fetch Auction & Lots details via Frontend-API
            this._fetchAuctionDataDetails(true).catch((rejectCause) => {
                if (promiseResolved === 0) {
                    promiseResolved = -21; // error code `2x` are for the API fetch
                    reject(".connect.reject: _fetchAuctionDataDetails.catch: " + rejectCause + "\n" + rejectCause.stack);
                }
            })

        });
    }


    /**
     * Private method, should NOT be called directly.  Use `AuctionsManager.leaveWebcast()`
     * @param wsCloseCode
     * @private
     */
    _destroy(wsCloseCode:number = 4009) {
        if (this.wsConn) {
            this.wsConn.close(wsCloseCode); // app-specific error connection close
            this.wsConn = null;
            this.connectedWS = false;
        }
    }

    private _handleWSClose() {
        this._mediaStreamingHandler.$destroy();
        _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionClear());
    }



    get controller():WebcastAuctionHandlerControllerType {
        return this._controller;
    }


    get mediaStreamingHandler():MediaStreamingHandler {
        return this._mediaStreamingHandler;
    }



    formatCurrencyAmount(valueCents:number, displayForEmpty:string = ''):string {
        if (!valueCents && valueCents !== 0) {
            return displayForEmpty;
        }
        const moneyHelper = getMoneyHelperForCurrencyCode(this.auctionConnectData.currencyCode)
        return moneyHelper.formatFromCents(valueCents);
    }




    private _fetchAuctionDataDetails(forceRefresh:boolean = false):Promise<Array<LotDetails>> {
        _loggerWSH.debug('._fetchAuctionDataDetails() forceRefresh:', forceRefresh);

        if (this._fetchAuctionDataDetailsPromise && ! forceRefresh) {
            _loggerWSH.debug('._fetchAuctionDataDetails: return existing Promise');
            return this._fetchAuctionDataDetailsPromise;
        }


        this._fetchAuctionDataDetailsPromise = new Promise((resolve, reject) => {

            // if we have a cached (previously fetched) data, and it's not a forced refresh, then resolve with the cached data
            if (this._fetchAuctionDataDetailsCachedResult && ! forceRefresh) {
                _loggerWSH.debug('._fetchAuctionDataDetails:Promise> resolve from cache');
                resolve(this._fetchAuctionDataDetailsCachedResult);
                return;
            }

            // fetch Auction & Lot details (description, images)
            this._remoteDataHandler.fetchAuctionWebcastLots(this.auctionConnectData.auctionId)
                .then((lots) => {
                        this._fetchAuctionDataDetailsCachedResult = lots;
                        resolve(this._fetchAuctionDataDetailsCachedResult);
                    },

                    (rejectReason) => {
                        _loggerWSH.error('._fetchAuctionDataDetails:Promise>ApiService.getAuctionWebcastLots.reject: ', rejectReason, rejectReason.stack);
                        reject("_fetchAuctionDataDetails.reject: " + rejectReason + "\nstack: " + rejectReason.stack);
                    });
        });

        _loggerWSH.debug('._fetchAuctionDataDetails: return new Promise');
        return this._fetchAuctionDataDetailsPromise;
    }



    private _sendWbsMessage(msgType:string, msgData?:{}) {
        _loggerWSH.debug('wsConn.send: ', msgType, msgData);

        if (this.wsConn && this.wsConn.readyState === 1) { // OPEN

            const channel = WbsCommChannelForClientMessageTypeMap.get(msgType);
            if (channel) {
                //only dispatch non-silent messages
                _reduxStore.dispatch(ActionsCreators.webcast.wbsCommStateUpdate({ channel, newStatus: WbsCommStatesEnum.WBS_COMM_STATUS_SENDING }));
            }

            const data = [ msgType, msgData ];
            const dataStr = JSON.stringify(data);
            this.wsConn.send(dataStr);
        }
        else {
            throw 'WebcastAuctionHandler.sendMessage() FAILED: WebSocket connection is not OPEN!'
        }
    }



    private async _handleOnWbsMessageReceived(msgType:string, msgPayloadObj:any) {

        const currentReduxState:ReduxStateType = _reduxStore.getState();
        const currentActiveWebcastReduxState:ReduxActiveWebcastSubStateType = currentReduxState.activeWebcast;

        const channel = WbsCommChannelForServerPushedMessageTypeMap.get(msgType);
        _reduxStore.dispatch(ActionsCreators.webcast.wbsCommStateUpdate({ channel, newStatus: WbsCommStatesEnum.WBS_COMM_STATUS_IDLE }));


        switch(msgType) {

            case ServerPushedMessageTypeEnum.AuctionDataSnapshot: {
                const msgPayload:PMAuctionDataSnapshot = msgPayloadObj;
                _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionDataSnapshotInit(msgPayload));

                const lotsDetails = await this._fetchAuctionDataDetails(false);
                _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionDataLotsDetailsUpdate({ lotsDetails }));

                this._mediaStreamingHandler.$update({ wbsAudioUid: msgPayload.streamingAudioUid, wbsVideoUid: msgPayload.streamingVideoUid });

                break;
            }


            case ServerPushedMessageTypeEnum.AuctionDataSyncStatusChanged: {
                const msgPayload:PMAuctionDataSyncStatusChanged = msgPayloadObj;

                if (msgPayload.outOfSync && msgPayload.mustApply) {
                    if (msgPayload.lots?.length) {
                        // only dispatch when mustApply and there's a lots "list" update
                        _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionDataSyncLotsListUpdate(msgPayload.lots));
                    }

                    // always update lot details if out of sync and mustApply
                    const lotsDetails = await this._fetchAuctionDataDetails(true);
                    _reduxStore.dispatch(ActionsCreators.webcast.webcastAuctionDataLotsDetailsUpdate({ lotsDetails, isDataSync: true }));

                    _reduxStore.dispatch(ActionsCreators.webcast.webcastClerkAuctionDataSyncCheckStatusUpdate(true));
                }
                else {
                    _reduxStore.dispatch(ActionsCreators.webcast.webcastClerkAuctionDataSyncCheckStatusUpdate(msgPayload.outOfSync));
                }

                break;
            }



            case ServerPushedMessageTypeEnum.ParticipantsUpdated: {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastClerkParticipantsUpdate(msgPayloadObj));
                break;
            }


            case ServerPushedMessageTypeEnum.WebcastStatusChanged: {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastStatusChange(msgPayloadObj));
                break;
            }


            case ServerPushedMessageTypeEnum.RoundCreated: {
                _reduxStore.dispatch(ActionBuilder.build(ACTION_TYPES.WEBCAST_CURRENT_ROUND_CREATE, msgPayloadObj));
                break;
            }


            case ServerPushedMessageTypeEnum.BiddingUpdated: {
                _reduxStore.dispatch(ActionBuilder.build(ACTION_TYPES.WEBCAST_CURRENT_ROUND_BIDDING_UPDATE, msgPayloadObj));
                break;
            }


            case ServerPushedMessageTypeEnum.RoundStopped: {
                const msgPayload:PMRoundStopped = msgPayloadObj;

                const roundStopWaitTime = msgPayload.stopType === RoundStopTypeEnum.CANCEL ? 1500 : 2000;

                const roundType = currentActiveWebcastReduxState.webcastCurrentRound.roundType;

                const roundStopActionPayload:CurrentRoundStopActionPayloadType = {
                    ... msgPayload,
                    roundType: roundType,
                    highestBid: currentActiveWebcastReduxState.webcastCurrentRoundBidding.highestBid,
                    isTransitional: true, // first-time dispatch of ROUND_STOP is *always* transitional
                };


                // dispatch the ROUND_STOP action (with isTransitional:true), regardless of roundType or stopType
                _reduxStore.dispatch(ActionsCreators.webcast.webcastCurrentRoundStop(roundStopActionPayload));


                if (msgPayload.nextRound) {
                    // `nextRound` is present when the currentRound will be closed (finished)
                    // `nextRound` is sent null from server when stopping a CHOICE round as SOLD|PENDING, but it *is* present when the CHOICE round is *closed*
                    const roundCloseActionPayload:CurrentRoundCloseActionPayloadType = {
                        roundId: msgPayload.roundId,
                        roundType: roundType,
                        stopType: msgPayload.stopType,
                        lotsIds: currentActiveWebcastReduxState.webcastCurrentRound.lotsIds,
                        bidsHistory: currentActiveWebcastReduxState.webcastCurrentRoundBidding.bidsHistory,
                        highestBid: currentActiveWebcastReduxState.webcastCurrentRoundBidding.highestBid,

                        nextRound: msgPayload.nextRound, // include nextRound
                    };

                    // this may be a CHOICE round closing
                    if (msgPayload.choiceLotsIds && msgPayload.choiceLotsIds.length) {
                        // if there are selections, send them in the action payload
                        roundCloseActionPayload.choiceLotsIds = msgPayload.choiceLotsIds;
                        roundCloseActionPayload.choiceUsersIds = msgPayload.choiceUsersIds;
                    }


                    setTimeout(() => {
                        _reduxStore.dispatch(ActionsCreators.webcast.webcastCurrentRoundClose(roundCloseActionPayload));
                    }, roundStopWaitTime);
                }
                else {
                    // a CHOICE stopping as SOLD|PENDING (`nextRound` is sent null from server)
                    // re-dispatch the ROUND_STOP action but with `isTransitional:false`
                    setTimeout(() => {
                        _reduxStore.dispatch(ActionsCreators.webcast.webcastCurrentRoundStop({ ... roundStopActionPayload, isTransitional: false } ));
                    }, roundStopWaitTime);

                }
                break;
            }



            case ServerPushedMessageTypeEnum.ChoiceLotsSelected: {
                _reduxStore.dispatch(ActionsCreators.webcast.choiceLotsSelectionUpdate(msgPayloadObj));
                break;
            }





            case ServerPushedMessageTypeEnum.LotsPassClosed: {
                _reduxStore.dispatch(ActionBuilder.build(ACTION_TYPES.WEBCAST_LOTS_PASS_CLOSE, msgPayloadObj));
                break;
            }



            case ServerPushedMessageTypeEnum.ChatMessagePosted: {
                _reduxStore.dispatch(ActionsCreators.webcast.webcastChatMessagePost(msgPayloadObj));
                break;
            }



            case ServerPushedMessageTypeEnum.StreamingHostUpdated: {
                this._mediaStreamingHandler.$update({ wbsAudioUid: msgPayloadObj.audioUid, wbsVideoUid: msgPayloadObj.videoUid });
                break;
            }

        }
    }

}
